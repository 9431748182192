import {Controller} from "stimulus"
import {faMapMarkerAlt as fasMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'

export default class extends Controller {
  static values = {
    markers: Array
  }

  connect() {
    gmapLoader.load().then((google) => {
      this.parser = new DOMParser();
      this.gInfoWindow = new google.maps.InfoWindow // Create only ONE infoWindow in the global scope to prevent having multiple infoWindow open
      this.gMap = new google.maps.Map(this.element, {
        center: this.centerCoordinate,
        streetViewControl: false,
        mapTypeControl: false,
        zoom: 13,
        mapId: "a83a6233872e047"
      })

      this._placeMarkers()
      this._placeDistricts()
      this._autoFitMap()
    })
  }

  _svgIcon(currentMarker) {
    const {prefix, iconName, icon} = fasMapMarkerAlt
    const [viewboxX, viewBoxY, _name, _, path] = icon
    const color = getComputedStyle(document.documentElement).getPropertyValue(`--${currentMarker.sector.color}`)
    const svgString = `
      <svg style="font-size:3em;" class="svg-inline--fa fa-${iconName} fa-2xl" aria-hidden="true" focusable="false" data-prefix="${prefix}" data-icon="${iconName}" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${viewboxX} ${viewBoxY}" data-fa-i2svg="">
        <path fill="${color}" d="${path}"></path>
      </svg>`

    return this.parser.parseFromString(
      svgString,
      "image/svg+xml",
    ).documentElement;
  }

  _placeMarkers() {
    this.markersValue.forEach(marker => {
      let options = {
        position: this._markerPosition(marker),
        title: marker.companyName,
        map: this.gMap,
        content: this._svgIcon(marker),
      }
      if (marker.label) {
        options["label"] = {
          text: marker.label,
          color: "white",
          fontWeight: "500"
        }
      }
      const gMarker = new google.maps.marker.AdvancedMarkerElement(options)

      if (marker.content) {
        gMarker.addListener("click", () => {
          this.gInfoWindow.setContent(marker.content)
          this.gInfoWindow.open(this.gMap, gMarker)
        })
      }
    })
  }

  _placeDistricts() {
    const src = 'https://previsionnel.ch/VDG_QUARTIER_VILLE.kml?t=1638168456'
    const kmlLayer = new google.maps.KmlLayer(src, {
      suppressInfoWindows: true,
      preserveViewport: false,
      map: this.gMap
    })

    kmlLayer.addListener('click', (event) => {
      const div = document.createElement('div')
      const h5 = document.createElement('h5')

      h5.appendChild(document.createTextNode(event.featureData.name))
      div.appendChild(h5)

      const ul = document.createElement('ul')
      ul.style.maxHeight = '160px'
      ul.style.overflow = 'auto'
      this.markersValue
          .filter((marker) => marker.district === event.featureData.name)
          .forEach((marker) => {
            const li = document.createElement('li')
            li.appendChild(document.createTextNode(marker.companyName))
            ul.appendChild(li)
          })
      div.appendChild(ul)

      this.gInfoWindow.setContent(div)
      this.gInfoWindow.setPosition(event.latLng)
      this.gInfoWindow.open(this.gMap)
    })
  }

  _autoFitMap() {
    const bounds = new (google.maps.LatLngBounds)
    this.markersValue.forEach(marker => {
      bounds.extend(this._markerPosition(marker))
    })
    this.gMap.fitBounds(bounds)
  }

  _markerPosition(marker) {
    return {
      lat: marker.coordinates[0],
      lng: marker.coordinates[1]
    }
  }

  get centerCoordinate() {
    return this._markerPosition(this.markersValue[0])
  }
}
